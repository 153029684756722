import { Spoiler } from "~/components/custom/spoiler";
import Trophy from "lucide-solid/icons/trophy";
import Laptop from "lucide-solid/icons/laptop";

export function Creator() {
  return (
    <section class="max-w-prose mx-auto flex flex-col md:grid grid-cols-[auto,auto] md:grid-rows-[auto,auto] py-10 gap-7">
      <img
        class="w-28 mx-auto rounded-full shadow-md shadow-sky-400/30 order-0"
        src="/atila-square.png"
        alt="Atila"
      />
      <p class="max-w-sm mx-auto order-2 px-6 text-center md:px-0 md:text-left">
        I enjoy building things and I'd like a system to keep waitlists and{" "}
        <Spoiler>
          contact with people interested in what I'm building. Once I was done,
        </Spoiler>
        I figured y'all might want that too.
      </p>
      <h2 class="text-3xl mx-auto md:mb-5 font-heading order-1 md:self-end">
        <span class="text-amber-400 font-extrabold text-[1em]">’sup! </span>
        I’m Atila
      </h2>
      <ul class="flex flex-col flex-wrap h-36 md:h-auto gap-x-10 gap-y-4 md:gap-2 order-3 mx-auto">
        <li>
          <img class="inline-block mr-2 relative -top-1" src="/solidjs.svg" />
          SolidJS DX
        </li>
        <li>
          <img class="inline-block mr-2 relative -top-1" src="/tauri.svg" />
          Tauri Core
        </li>
        <li>
          <img class="inline-block mr-2 relative -top-1" src="/gde.svg" />
          GDE: Web
        </li>
        <li>
          <Laptop class="inline-block w-4 mr-2 relative -top-px text-cyan-300" />
          Indie Hacker
        </li>
        <li>
          <Trophy class="inline-block w-4 mr-2 relative -top-px text-amber-300" />
          Unawarded Developer
        </li>
      </ul>
    </section>
  );
}
