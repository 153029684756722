import { type JSX, createSignal } from "solid-js";

export function Spoiler(props: { children: JSX.Element }) {
  const [showText, setShowText] = createSignal(false);
  return (
    <span
      class="pr-2"
      classList={{
        "cursor-zoom-in filter blur-sm": !showText(),
        "cursor-zoom-out": showText(),
      }}
      onClick={() => setShowText(!showText())}
    >
      {props.children}
    </span>
  );
}
