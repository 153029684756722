import { BentoBox, BentoItem } from "../custom/bento-grid";
import Chart from "lucide-solid/icons/chart-line";

export function Features() {
  return (
    <section class="py-36 max-w-7xl mx-auto">
      <BentoBox>
        <BentoItem title="Unlimited Users">
          <span>
            Let's not suffer from success. A growing community should always be
            a great thing. We won't charge you more for it.
          </span>
        </BentoItem>
        <BentoItem isHighlight title="Metrics to Grow" icon={Chart}>
          <p class="mb-5">
            Our set of features and limits are designed to let you grow free.
            But you can't improve what you can't measure, and we know that.
          </p>
          <p class="font-bold mb-5 text-xl">
            We got <span class="text-amber-300">metrics</span>. Just the right
            amount.
          </p>
          <p>
            Simple to let you do your thing, but professional enough that you
            know what's going on.
          </p>
        </BentoItem>

        <BentoItem title="Tailored for You">
          <span>
            Write your own copy. Choose some of our pre-defined templates or
            write your own in JSX. No footnotes or watermarks either way. You
            look professional and invested from the very beginning.
          </span>
        </BentoItem>
        <BentoItem title="No Vendor Lock-in">
          <span>
            Your community is yours. Wanna go somewhere else? Simply click
            export and you take your contacts with you. No strings attached.
          </span>
        </BentoItem>
        <BentoItem title="Security & Privacy">
          <span>
            All services we use are SOC 2 Type II certified. This means
            Security, Availability, Processing Integrity, Confidentiality, and
            Privacy. <b>No corners cut</b>.
          </span>
        </BentoItem>
      </BentoBox>
    </section>
  );
}
