import { type JSX, type ValidComponent, Show } from "solid-js";
import { Dynamic } from "solid-js/web";

interface BentoItemProps {
  isHighlight?: boolean;
  title: string;
  children: JSX.Element;
  icon?: ValidComponent;
}

const highlightStyles =
  "sm:col-span-2 sm:order-first md:order-none md:row-span-2 md:col-span-1";

const accentBox =
  "hover:border-amber-500  hover:shadow-amber-600 dark:hover:border-amber-300  dark:hover:shadow-amber-200";
const accentTitle = "text-amber-300  dark:group-hover:text-amber-300";

export const BentoItem = (props: BentoItemProps) => {
  return (
    <li
      class={`group order relative hover:border-transparent border-2 border-neutral-900 dark:border-indigo-100/10 rounded-md p-4 group transition-all shadow-inner transform hover:-translate-y-1 overflow-hidden ${
        props.isHighlight ? highlightStyles : ""
      } ${accentBox}`}
    >
      <h2 class={`not-italic font-heading text-3xl pb-3 block ${accentTitle}`}>
        {props.title}
      </h2>

      <Show when={props.icon}>
        {(iconComponent) => (
          <div class="absolute top-0 left-0 h-full w-full flex -z-10 items-center justify-end">
            <Dynamic
              component={iconComponent()}
              size="300"
              class="group-hover:animate-rocket-shake text-indigo-200/10 absolute left-0 bottom-2"
            />
          </div>
        )}
      </Show>

      <p class="text-xl leading-8">{props.children}</p>
    </li>
  );
};

export const BentoBox = (props: { children: JSX.Element }) => {
  return (
    <ul class="max-w-full px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 grid-rows-1 sm:grid-rows-2 gap-5 sm:gap-4">
      {props.children}
    </ul>
  );
};
