import {
  createSignal,
  createEffect,
  onCleanup,
  splitProps,
  type JSX,
} from "solid-js";
import Rocket from "lucide-solid/icons/rocket";
import { isServer } from "solid-js/web";
import { cn } from "~/lib/tailwind-merge";

interface Props extends JSX.ButtonHTMLAttributes<HTMLButtonElement> {
  children: JSX.Element;
}

export function CTA(props: Props) {
  const [solid, buttonProps] = splitProps(props, [
    "children",
    "class",
    "disabled",
  ]);
  const [isActive, setIsActive] = createSignal(false);
  let animationFrameId: number;

  createEffect(() => {
    const canvas = document.getElementById(
      "canvas-button"
    ) as HTMLCanvasElement;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const stars: { x: number; y: number; size: number; speed: number }[] = [];

    // Create smaller stars
    for (let i = 0; i < 100; i++) {
      stars.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        size: Math.random() * 1 + 0.5, // Smaller size range
        speed: Math.random() * 5 + 3, // Increased speed range
      });
    }

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "rgba(255,255,255, 0.4)";

      stars.forEach((star) => {
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.size, 0, Math.PI * 2);
        ctx.fill();

        if (isActive()) {
          star.x -= star.speed;
          star.y += star.speed / 2;

          if (star.x < 0) star.x = canvas.width;
          if (star.y > canvas.height) star.y = 0;
        }
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();
  });

  onCleanup(() => {
    if (!isServer) {
      cancelAnimationFrame(animationFrameId);
    }
  });

  return (
    <button
      {...buttonProps}
      disabled={solid.disabled}
      class={cn(
        solid.class,
        "group relative overflow-hidden bg-black/20 text-white font-bold text-4xl py-4 px-8 rounded-full transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75 shadow-[0px_0px_40px_rgba(255,255,255,0.2)]"
      )}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
    >
      <canvas
        id="canvas-button"
        class="absolute inset-0 w-full h-full"
        width={200}
        height={60}
      />
      <span class="relative z-10 mr-2">{solid.children}</span>
      <span class="relative z-10 inline-block transition-transform duration-300 group-hover:animate-rocket-shake group-focus:animate-rocket-shake">
        <Rocket class="inline-block" size={25} stroke-width={"3"} />
      </span>
    </button>
  );
}
