export function Footer() {
  return (
    <footer class="pt-20 pb-6 mx-auto max-w-2xl">
      <div class="flex flex-col-reverse gap-10 md:gap-0 md:flex-row justify-between items-center">
        <p class="text-center text-gray-400 text-md">
          by{" "}
          <a href="https://atila.io" rel="noopener">
            Atila.io
          </a>
        </p>
        <ul class="flex flex-row gap-5">
          <li>
            <a
              class=""
              href="https://atila.io/youtube"
              rel="noopener"
              target="_blank"
            >
              <img
                class="w-6 h-6 opacity-45 transform hover:opacity-100 hover:scale-110 transition-all"
                src="/ytube.svg"
              />
              <span class="sr-only">youtube</span>
            </a>
          </li>
          <li>
            <a href="https://atila.io/bsky" rel="noopener" target="_blank">
              <img
                class="opacity-45 transform hover:opacity-100 hover:scale-110 transition-all"
                src="/bluesky.svg"
              />
              <span class="sr-only">bluesky</span>
            </a>
          </li>
          <li>
            <a
              class="opacity-45 transform hover:opacity-100 hover:scale-110 transition-all"
              href="https://atila.io/x"
              rel="noopener"
              target="_blank"
            >
              <img src="/xicon.svg" />
              <span class="sr-only">X (formerly known as Twitter)</span>
            </a>
          </li>
          <li>
            <a href="https://atila.io/linkedin" rel="noopener" target="_blank">
              <img
                class="opacity-45 transform hover:opacity-100 hover:scale-110 transition-all"
                src="/linkedin.svg"
              />
              <span class="sr-only">LinkedIn</span>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
