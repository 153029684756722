import { Meta, Title } from "@solidjs/meta";
import { Creator } from "~/components/sections/creator";
import { FAQ } from "~/components/sections/faq";
import { Features } from "~/components/sections/features";
import { Footer } from "~/components/sections/footer";
import { Hero } from "~/components/sections/hero";
import { Waitlist } from "~/components/sections/waitlist";
// import { ThemeDropdown } from "~/components/solid-ui/theme-dropdown";

export default function Home() {
  const domain = () =>
    import.meta.env.PROD ? "https://listup.com" : "http://localhost:3000";
  return (
    <>
      <Title>ListUp</Title>
      <Meta name="description" content="ListUp is a new way to create lists" />
      <Meta name="og:title" content="ListUp" />
      <Meta name="og:image" content={`${domain()}/og.png`} />
      <Meta
        name="og:description"
        content="ListUp is a new way to create lists"
      />
      <Meta name="og:url" content={domain()} />
      <Meta name="og:type" content="website" />
      <Meta name="twitter:card" content="summary_large_image" />
      <Meta name="twitter:site" content="@AtilaFassina" />
      <Meta name="twitter:creator" content="@AtilaFassina" />
      <Meta name="twitter:title" content="ListUp" />
      <Meta
        name="twitter:description"
        content="ListUp is a new way to create lists"
      />
      <Meta name="twitter:image" content={`${domain()}/og.png`} />
      <Meta name="twitter:url" content={domain()} />

      {/* <div>
        <ThemeDropdown />
      </div> */}
      <div>
        <Hero />
        <Features />
        <Waitlist />
        <Creator />
        <FAQ />
      </div>
      <Footer />
    </>
  );
}
