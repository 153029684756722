import {
  TextField,
  TextFieldLabel,
  TextFieldInput,
} from "../solid-ui/text-field";
import { action, useSubmission } from "@solidjs/router";
import { sendEmail } from "~/lib/email/mailing";
import { contactExists } from "~/lib/db/audience";
import { CTA } from "../cta";
import { Show } from "solid-js";
import { clientOnly } from "@solidjs/start";
import { SparklesText } from "../mystic-ui/sparkle-text";

const ConfettiExplosion = clientOnly(() =>
  import("solid-confetti-explosion").then((mod) => ({
    default: mod.ConfettiExplosion,
  }))
);

const subscribe = action(async (data: FormData) => {
  "use server";

  const registeringEmail = data.get("email") as string;
  const preferredName = data.get("name") as string;

  if (await contactExists(registeringEmail)) {
    throw new Error("Email already registered");
  } else {
    await sendEmail({
      email: registeringEmail,
      name: preferredName,
    });

    return {
      result: true,
    };
  }
});

export function Waitlist() {
  const registration = useSubmission(subscribe);

  return (
    <section class="relative w-full backdrop:blur-sm py-20">
      <header class="grid place-items-center">
        <SparklesText>
          <h2 class="font-heading text-6xl text-amber-300 mb-16">
            List<span class="text-neutral-200 font-thin px-1">yourself</span>Up
            <span class="text-neutral-200 font-thin">!</span>
          </h2>
        </SparklesText>
      </header>
      <form
        method="post"
        action={subscribe}
        class="grid place-items-center gap-10 w-full"
      >
        <div class="grid md:grid-cols-2 gap-7">
          <TextField class="grid w-full place-items-center gap-1.5">
            <TextFieldLabel
              for="name"
              class="text-lg text-indigo-200 font-heading"
            >
              Preferred name
            </TextFieldLabel>
            <TextFieldInput
              type="text"
              id="name"
              name="name"
              placeholder="Silvio Santos"
              class="bg-white/10 py-5 w-80 ring-2 text-lg text-white placeholder:text-neutral-400 ring-indigo-400/30 focus:ring-amber-600 ring-offset-4 ring-offset-indigo-900"
            />
          </TextField>
          <TextField class="grid w-full place-items-center gap-1.5">
            <TextFieldLabel
              for="email"
              class="text-lg text-indigo-200 font-heading"
            >
              e-mail
            </TextFieldLabel>
            <TextFieldInput
              type="email"
              id="email"
              name="email"
              placeholder="silvio@sbt.com"
              class="bg-white/10 py-5 w-80 ring-2 text-lg text-white placeholder:text-neutral-400 ring-indigo-400/30 focus:ring-amber-600 ring-offset-4 ring-offset-indigo-900"
            />
          </TextField>
        </div>
        <Show
          when={!registration.result}
          fallback={
            <>
              <ConfettiExplosion particleCount={200} force={0.3} />
              <p class="text-2xl font-heading">
                🔥 Let's <span class="text-amber-300 ">freaking</span> go! 🔥
              </p>
            </>
          }
        >
          <CTA
            type="submit"
            class="disabled:opacity-75"
            disabled={registration.pending}
          >
            Let's ship!
          </CTA>
        </Show>
        <Show when={registration.error}>
          {(err) => (
            <div>
              <span>Error: {JSON.stringify(err().message)}</span>
            </div>
          )}
        </Show>
      </form>
      <p class="text-center pt-20 text-indigo-200">
        No spam, just updates on this and other projects that may interest you.
      </p>
      <p class="text-center text-indigo-200">Unsubscribe anytime, of course.</p>
    </section>
  );
}
